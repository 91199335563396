<template>
    <div>
        <div class="row">
            <div class="col-sm-6 col-md-4 mb-3" v-for="wallet in getOwner" :key="wallet.id">
                <CardWallet :isActive="cardActive.id" :wallet="wallet" @click="selectWallet(wallet)" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CardWallet from '../../wallet/CardWallet.vue'
export default {
    components: {
        CardWallet
    },
    data () {
        return {
            cardActive: {
                id: null
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('wallet', ['getWallets']),
        getData () {
            this.loading = true
            this.getWallets().then(() => {
                this.loading = false
            })
        },
        selectWallet(wallet) {
            this.cardActive = wallet
            this.$emit('select', wallet)
        }
    },
    computed: {
        ...mapGetters('wallet', ['getOwner'])
    }
}
</script>