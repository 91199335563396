<template>
    <b-card no-body class="bg-secondary wallet" :class="isActive == wallet.id ? 'bg-active' : ''">
        <div class="bg-ba">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <img v-if="wallet.asset" :src="wallet.asset.icon" class="img-fluid w-100 mt-3">
                        <h3 class="mt-3" v-if="wallet.asset">
                            {{ wallet.asset.name }}
                        </h3>
                        <div class="price">
                            ${{ fixedBalance(wallet.balance_usd) }}
                        </div>
                    </div>
                    <div class="col-6 text-right right-text">
                        <Chart :id="wallet.id" :currency="wallet.currency" />
                        <div class="price-usd">
                            {{ fixedBalance(wallet.balance) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>
<script>
import { mapActions } from 'vuex'
import Chart from './SecondChart.vue'
export default {
    components: {
        Chart
    },
    props: {
        wallet: {
            required: true
        },
        isActive: {
            default: false
        },
        isLock: {
            default: false
        }
    },
    methods: {
        ...mapActions('wallet', ['getBalance']),
        fixedBalance (data) {
            return data ? data.toFixed(3) : 0
        }
    },
    watch: {
        isActive: function (val) {
            if(val == this.wallet.id) {
                this.getBalance({address: this.wallet.address, currency: this.wallet.currency}).then(response => {
                    this.wallet.balance = response[0]
                    this.wallet.balance_usd = response[1]
                })
            }
        }
    }
}
</script>
<style scoped>
    .wallet img{
        max-width: 40px;
    }
    .wallet .price {
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .price-usd{
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
    .bg-active{
        background-color: #E2BD50 !important;
    }
    .bg-active h4, .bg-active div{
        color: black !important;
    }
</style>